import React from "react";

import * as C from "./styles";

const Metodologia: React.FC = () => {
  return (
    <C.Container id="metodologia">
      <header className="cabecalho">
        <h2>Metodologia</h2>
      </header>
      <div className="metoBx">
        <p>
          É de suma importância para o sucesso do trabalho a forte interação e
          relacionamento humano entre as empresas. Envolverá uma intimidade com
          troca de dados e informações essenciais, criando um elo de confiança,
          que exigirá muitas vezes contrato de confidencialidade. Padrão de
          etapas para desenvolvimento dos trabalhos:
        </p>
        <figure className="imgBx">
          <img src="img/meto-img.png" alt="Metodologia" />
        </figure>
        <p>
          Forte aliado na eficiência e produtividade dos serviços é a escolha
          correta de ferramentas e técnicas utilizadas pelos nossos
          especialistas. Este conhecimento será transmitido durante a execução
          dos serviços. Treinamentos poderão assegurar a multiplicação deste
          know-how garantindo um legado de
          conhecimento para toda empresa. Nossa equipe também aplica a
          metodologia FEL de maturidade em projetos conforme seu porte ou
          requerimento do cliente.
        </p>
      </div>
    </C.Container>
  );
};

export default Metodologia;
