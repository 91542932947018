import styled from "styled-components";
// @ts-ignore
import { Link as LinkS } from 'react-scroll';
import { Link as LinkR } from 'react-router-dom';

export const NavBx = styled.header<{ scrollProp: boolean }>`
  width: 100%;
  height: ${props => props.scrollProp ? "100px" : "120px"};
  padding: 0 80px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.scrollProp ? "#fff" : "transparent"};
  z-index: 1000;
  transition: 0.35s ease-in-out;
  .lista {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 2rem;
    li a {
      color: ${props => props.scrollProp ? "#0d0d0d" : "fff"};
      &:hover {
        color: #ff9933;
      }
    }
    .flagBox {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }  

  .toggle {
    display: none;
    visibility: hidden;
  }

  .logBx {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.1rem;
  }

  @media screen and (max-width: 1300px) {
    & {
      padding: 0 30px;
      background: #fff;
      height: 80px;
    }
    .toggle {
      display: flex;
      justify-content: center;
      align-items: center;
      visibility: visible;
      cursor: pointer;
      & > * {
        font-size: 1.5rem;
        color: #0d0d0d;
      }
    }
    .lista {
      position: fixed;
      top: 80px;
      left: -100%;
      visibility: hidden;
      flex-direction: column;
      justify-content: center;
      transition: .3s;
      background: #fff;
      z-index: 100;
      width: 100%;
      min-height: calc(100vh - 80px);
      &.active {
        left: 0;
        visibility: visible;
      }
    }
  }
`;


export const LinkScroll = styled(LinkS)`
  font-size: 1.1rem;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 1px;
  text-transform: uppercase;
  &:hover {
    color: #ff9933;
  }
  @media screen and (max-width: 1300px) {
    font-size: 2.3rem;
    color: #0d0d0d;
  }
`;

export const LogoScroll = styled(LinkS)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 190px;
  user-select: none;
  cursor: pointer;

  img {
    max-width: 100%;
    display: block;
  }
`;

export const Flag = styled(LinkR)`
  padding: 5px;
  width: 78px;
  height: 51px;
  display: grid;
  place-items: center;
  color: #fff !important;
  & > * {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;