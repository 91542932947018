import React from "react";

import {
  About,
  Banner,
  Metodologia,
  Partners,
  Products,
  Projects,
} from "../containers/Ptbr";

const Ptbr: React.FC = () => (
  <>
    <Banner />
    <About />
    <Metodologia />
    <Products />
    <Projects />
    <Partners />
  </>
);

export default Ptbr;
