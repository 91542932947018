import React from "react";

import { BannerContainer } from "./styles";

const Banner: React.FC = () => {
  return (
    <BannerContainer id="banner">
      <div className="videoBx">
        <video src="videos/video.mp4" autoPlay loop muted></video>
      </div>
      <div className="content">
        <span className="sub-title">Logística | Engenharia | Tecnologia | ESG</span>
        <h2>4DECISION</h2>
        <p>Soluções certas para novas conquistas!</p>
      </div>
    </BannerContainer>
  );
};

export default Banner;
