import styled from "styled-components";

export const CardReadMoreContainer = styled.div<{ isShowingMore: boolean }>`
  width: 100%;
  max-width:  ${props => props.isShowingMore ? '550px' : '400px'};
  position: relative;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  cursor: pointer;

  .imgBx {
    position: relative;
    object-fit: contain;
    width: 100%;
    height: 232px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .text {
    padding: 15px 20px;
    max-width: 100%;
    min-height: ${props => props.isShowingMore ? 'fit-content' : '243px'};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 6px;
    background: #fff;

    img {
        max-width: 100%;
        object-fit: cover;
    }

    h4 {
      font-size: 15px;
      color: #161616;
      font-weight: 500;
      text-decoration: underline;
    }

    p {
      text-align: justify;
      font-weight: 500;
      color: #4A7DFF;
      text-align: center;
    }

    button {
      padding: 8px 16px;
      background-color: ${props => props.isShowingMore ? 'transparent': '#ff9933'};
      color: ${props => props => props.isShowingMore ? '#ff9933' : '#fff'};
      border-radius: 20px;
      border: 1px solid #ff9933;
      transition: all 0.3s;
    }
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    max-width: 400px;
    .text {
      padding: 20px 0;
      p {
        font-size: 0.9rem;
      }
    }
  }
`;
