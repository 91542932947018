import React from 'react';
import { Routes, Route } from 'react-router-dom';

import GlobalStyle from './styles/global';
import { Navbar, Footer } from './components';
import Ptbr from './pages/Ptbr';
import Eng from './pages/Eng';

const App: React.FC = () => (
  <>
    <div className="app">
      <Navbar />
      <Routes>
        <Route path="/" element={<Ptbr />} />
        <Route path="/eng" element={<Eng />} />
      </Routes>
      <Footer />
    </div>
    <GlobalStyle />
  </>
);

export default App;
