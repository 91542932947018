import styled from "styled-components";

export const Wrapper = styled.section`
    position: relative;
    width: 100%;
    padding: 20px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    h2 {
        font-size: clamp(2rem, 2vw, 3rem); 
        font-weight: 500;
        letter-spacing: 2px;
        cursor: pointer;
        transition: 0.3s;
        position: relative;
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 40%;
                height: 2px;
                background: #0d0d0d;
                transition: .3s;
            }
            &:hover {
                letter-spacing: 5px;
            }
            &:hover::after {
                width: 100%;
            }
    }
    .scBx {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 100px;
        flex: 1;
        @media screen and (max-width: 670px) {
            & {
                padding: 15px 0;
                gap: 25px;
            }
        }
    }
    @media screen and (max-width: 670px) {
        & {
            padding: 0 30px;
            gap: 5px;
        }
    }
`;

export const Card = styled.div`
    position: relative;
    width: 600px;
    min-height: 714px;
    background: #005E9B;
    padding: 15px 0;
    border-radius: 10px;
    .header {
        position: relative;
        display: grid;
        place-items: center;
        grid-template-columns: 1fr 3fr;
        margin-bottom: 20px;
        .imgBx {
            position: relative;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            box-shadow: 0 0 10px rgba(255, 255, 255,.1);
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            &::after {
                content: '';
                position: absolute;
                bottom: -6px;
                left: 50%;
                transform: translateX(-50%);
                height: 1px;
                width: 90%;
                background: #5bcbf0;
                opacity: 0.25;
            }
        }
        .name {
            text-align: left;
            color: #fff;
            font-weight: 600;
            font-size: 1.4rem;
        }
        @media screen and (max-width: 650px) {
            & {
                grid-template-columns: 1fr;
                grid-gap: 10px;
                padding: 0 20px;
                .name {
                    font-size: 1.2rem;
                    text-align: center;
                }
            }
        }
    }
    .content {
        padding: 0 30px;
        color: #fff;
        p:nth-child(1) {
            font-size: 1.2rem;
            font-weight: 700;
            letter-spacing: 1px;
            margin-bottom: 5px;
        }
        p:nth-child(2) {
            text-align: justify;
        }
    }
    @media screen and (max-width: 670px) {
        & {
            width: 100%;
            max-width: 600px;
            .content {
                text-align: center;
                p:nth-child(1) {
                    text-align: center;
                }
            }
        }
    }
`;