import React from 'react';

import { BannerContainer } from './styles';

const Banner: React.FC = () => {
  return (
    <BannerContainer id="banner">
      <div className="videoBx">
        <video src="videos/video.mp4" autoPlay loop muted></video>
      </div>
      <div className="content">
        <span className="sub-title">Logistics | Engineering | Technology | ESG</span>
        <h2>4DECISION</h2>
        <p>Right solutions for new achievements!</p>
      </div>
    </BannerContainer>
  );
};

export default Banner;
