import React from "react";

import * as C from "./styles";

const Metodologia: React.FC = () => {
  return (
    <C.Container id="metodologia">
      <header className="cabecalho">
        <h2>Method</h2>
      </header>
      <div className="metoBx">
        <p>
          A close and smooth people relationship is paramount for goals
          achievement. The service takes a deep data exchange and information to
          achieve best solutions creating a bond of trust, requiring as
          necessary confidentiality agreement. Usual steps for work development
          are:
        </p>
        <figure className="imgBx">
          <img src="img/method-eng.png" alt="Metodologia" />
        </figure>
        <p>
          The right choice of tools and techniques is important for service
          productivity and efficiency. This knowledge will be transmitted to
          customer team during work development. This know-how can be enhanced
          through training letting a legacy for entire company. Our team applies
          FEL´s methodology for projects development according to the size of
          the project or customer requirement.
        </p>
      </div>
    </C.Container>
  );
};

export default Metodologia;
