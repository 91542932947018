import styled from "styled-components";

export const BannerContainer = styled.section`
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .videoBx {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
    }
    .content {
        max-width: 600px;
        text-align: center;
        z-index: 10;
        .sub-title {
            font-size: .9rem;
            text-transform: uppercase;
            color: #339966;
            font-weight: 600;
            letter-spacing: 2px;
        }
        h2 {
            font-size: clamp(2rem, 3.5rem, 5rem);
            font-weight: 600;
            letter-spacing: 1px;
            color: #f0f0f0;
        }
        p {
            font-weight: 500;
            line-height: 1.6em;
            font-size: 1.2rem;
            color: #fff;
        }
        @media screen and (max-width: 800px) {
            & {
                padding: 0 40px;
            }
            .sub-title {
                font-size: .6rem;
            }
            h2 {
                font-size: 2rem;
            }
            p {
                font-size: 1rem;
            }
        }
    }
    @media screen and (max-width: 991px) {
        & {
            min-height: calc(100vh - 80px);
            margin-top: 80px;
        }
    }
`;