import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    padding: 20px 50px;
    background: #161616;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    h2 {
        text-align: center;
        font-size: 3rem;
        font-weight: 600;
        color: #fff;
        cursor: pointer;
        transition: 0.3s;
        position: relative;
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 40%;
                height: 2px;
                background: #fff;
                transition: .3s;
            }
            &:hover {
                letter-spacing: 5px;
            }
            &:hover::after {
                width: 100%;
            }
    }
    .cardBx {
        width: 100%;
        position: relative;
        margin-top: 25px;
        display: grid;
        place-items: center;
        grid-template-columns: repeat(3, .5fr);
        grid-gap: 2.5rem;
        max-width: 100%;
        padding: 0 50px;

        @media screen and (max-width: 1575px) {
            & {
                grid-template-columns: repeat(2, .5fr);
            }
        }
        @media screen and (max-width: 1100px) {
            & {
                grid-template-columns: 1fr;
                text-align: center;
            }
        }
    }
`;

export const Card = styled.div`
    position: relative;
    width: 300px;
    height: 400px;
    background: #fff;
    transform-style: preserve-3d;
    transform: perspective(900px);
    box-shadow: 0 0 40px rgba(255, 255, 255,.25);
    transition: 1s;
    .imgBx {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        transform-origin: left;
        transform-style: preserve-3d;
        background: #000;
        transition: 0.7s;
        overflow: hidden;

        .front-side {
            /* box-shadow: 0 0 0 300px #ff9933; */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            z-index: 100;
            &::before {
                content: attr(data-project);
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%);
                z-index: 101;
                font-size: 2rem;
            }
        }
        & img:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transform-style: preserve-3d;
            transform: rotate(0);
            width: 100%;
            height: 100%;
            z-index: 99;
        }
    }
    .detalhes {
        position: absolute;  
        top: 0;
        left: 0;
        width: 100%; 
        height: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        .content {
            text-align: justify;
            h3 {
                font-weight: 500;
                font-size: 1.1rem;
                line-height: 1.5em;
            }
        }
    }
    &:hover {
        transform: translateX(50%);
        .imgBx {
            .front-side {
                z-index: 0;
            }
        }
    }            
    &:hover .imgBx {
        transform: rotateY(-180deg);
    }
    @media screen and (max-width: 450px) {
        & {
            max-width: 150px;
            height: 300px;
            .detalhes {
                .content {
                    font-size: 1rem;
                }
            }
        }
    }
`;