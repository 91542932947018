import React from "react";

import { IProduct, IProject, IPartner } from "../types";

export const dataProducts: IProduct[] = [
  {
    dataAttr: "Engenharia",
    img: "img/prod2.png",
    txt: "Estudo Conceitual, Estudo de Viabilidade, Projetos de Engenharia, Plano Diretor, Operacional, Simulação Dinâmica.",
    hiddenContent: () => (
      <>
        <p>
          <span className="highlight">Projeto</span> tem como objetivo
          solucionar um problema específico no menor tempo possível e pode
          exigir diversas especialidades. Um projeto de operações logísticas
          contêm os critérios do projeto (premissas, especificações e requisitos
          como nível de serviço), o desenho dos processos, a otimização de
          operações, o dimensionado de recursos, a análise de capacidade do
          sistema, a análise dos riscos e a viabilidade econômica da
          alternativa. Layout das instalações e infraestrutura e cálculos de{" "}
          <span className="highlight">simulação dinâmica</span> das operações
          são também produtos importantes, este segundo principalmente para
          operações complexas, discretas e estocásticas. Durante o ciclo de
          desenvolvimento, os projetos ganham maturidade após cada etapa de
          detalhamento. A primeira etapa, o Estudo{" "}
          <span className="highlight">Conceitual</span> é caracterizado pela
          análise de várias alternativas. A seguir, o Estudo de Viabilidade
          detalha a melhor alternativa identificada na fase anterior. Projetos
          de Engenharia Estrutural podem ser necessários nesta fase (4Decision
          trabalha com consultores parceiros),{" "}
          <span className="highlight">mas são as peças principais</span> no
          terceiro estágio denominado Projeto Básico.
        </p>
        <p>
          Um <span className="highlight">Plano Diretor</span> visa reduzir ou
          bloquear os impactos vindos de fora da empresa, geralmente
          relacionados ao mercado ou a aspectos econômicos, tecnológicos,
          sociais, políticos, jurídicos e ambientais. Propõe diferentes soluções
          e / ou um conjunto de projetos, usualmente detalhados nos próximos 5
          anos, mas com um escopo de análise de 20 a 30 anos. O Plano Diretor é
          revisado anualmente e orienta o planejamento de longo prazo da
          empresa.
        </p>
        <img src="img/prod1-inner-image.jpg" alt="" />
        <p>
          A <span className="highlight">simulação dinâmica</span> de processos
          ou sistemas é uma técnica de pesquisa operacional muito útil para a
          tomada de decisões de operações logísticas complexas. Permite modelar,
          ou seja, representar qualquer operação no computador, por exemplo o
          fluxo de pessoas ou veículos em uma região de sua cidade, com alta
          precisão, por mais estocástico ou despadronizado seja sua operação.
        </p>
        <p>
          Isso é possível devido à experiência de nossa equipe e recursos de
          tecnologia de programação e animação, além de análises estatísticas de
          dados, oferecidas por softwares e plataformas digitais no mercado.
          Entre os resultados, mede a capacidade operacional, identifica
          gargalos, indica recursos e nível de serviço, de forma sistêmica e
          holística, replicando de forma realista a simultaniedade de eventos. E
          o mais importante, simula cenários futuros, possibilitando testar
          diferentes soluções “na prancheta”, garantindo confiança na sua tomada
          de decisão, possibilitando a você a melhor solução com menor
          investimento.
        </p>
      </>
    ),
  },
  {
    dataAttr: "Comercial",
    img: "img/prod1.png",
    txt: "Business Plan.",
    hiddenContent: () => (
      <p>
        {" "}
        O <span className="highlight">Business Plan</span> é o resultado do
        processo de desenvolvimento do planejamento estratégico do negócio,
        podendo ser aplicado para um novo produto ou reposicionamento da empresa
        no mercado. Dependendo da aplicação diferentes técnicas e métodos podem
        ser utilizados, como as 5 Forças de Porter, SWOT, Missão, Visão e
        Valores, objetivos estratégicos, BSC, SIPOC, Viabilidade Econômica,
        entre outras. O produto é usualmente uma apresentação executiva e um
        relatório.
      </p>
    ),
  },
  {
    dataAttr: "Gestão",
    img: "img/prod3.png",
    txt: "Análise do negócio com foco na gestão integrada dos indicadores de controle dos processos em nível estratégico, tático e operacional.",
    hiddenContent: () => (
      <p>
        Focado na gestão integrada da informação, visando analisar a suficiência
        (quantificação) e qualidade das informações da sua empresa. Exige
        diferentes especialidades (i) gestão de negócios, avaliando o modelo de
        gestão das empresa através de seus indicadores de controle do negócio,
        indicadores produtividade do desempenho (KPIs) e parâmetros de benchmark
        operacional, (ii) Business Intelligence (BI) para avaliar sua
        infraestrutura de software e hardware, (iii) Automação, para avaliar o
        nível de automação das operações da sua empresa, e (iv) Inteligência
        Artificial, para analisar a aplicabilidade de modelos preditivos e de
        projeção utilizados pelas áreas de marketing, comercial e planejamento
        estratégico.{" "}
      </p>
    ),
  },
  {
    dataAttr: "Educacional",
    img: "img/prod4.png",
    txt: "Treinamento em ferramentas técnicas e de gestão.",
    hiddenContent: () => (
      <p>
        Treinamento de novas ferramentas e metodologias usualmente aplicadas
        durante a consultoria 4Decision, garantindo a difusão do conhecimento em
        sua empresa e a maximização de seus resultados.
      </p>
    ),
  },
  {
    dataAttr: "ESG",
    img: "img/prod5.jpg",
    txt: "Modelagem de Negócios Estudo de Viabilidade EVTEA Inventário GEECrédito CarbonoAutomação de Processos",
    hiddenContent: () => (
      <p>
        Modelagem de Negócios Estudo de Viabilidade EVTEA Inventário GEECrédito
        CarbonoAutomação de Processos
      </p>
    ),
  },
];

export const dataProjects: IProject[] = [
  {
    title:
      "Planejamento, contratação e implantação : novo canal 3PL para exportação de 100 kt de minério de ferro (2008)",
    img: "img/img8.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Projeto Conceitual Porto 4.0 : Maior Termina Portuário de Contêiner do Peru 1,5 MTEUs (2019)",
    img: "img/img2.png",
    icon: "img/proj-icon.png",
  },
  {
    title: "EVTEA Arrendamento de porto público para indústria (2018)",
    img: "img/img3.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Trade Off Study : Alternativas para carregamento de navios de grãos com porão fechado (2017)",
    img: "img/img4.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Projeto ESG de Supressão de particulado em Terminal Portuário de Carvão (2016)",
    img: "img/img5.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Plano Diretor Operacional dos principais Terminais Portuários de Grãos e Carvão do Brasil, 40 Mta (2015)",
    img: "img/img6.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Plano Diretor Operacional dos principais Terminais Portuários de Minério de ferro do Brasil, 120 Mta (2012-14)",
    img: "img/img7.png",
    icon: "img/proj-icon.png",
  },
];

export const partnerData: IPartner[] = [
  {
    name: "Luiz André Gervásio de Menezes",
    formacao: "Engenheiro Civil",
    faculdade: "Universidade Federal do Espírito Santo (UFES)",
    expirience:
      "21 anos de experiência atuando no negócio de logística para os maiores players mundiais dos setores de siderurgia (Arcelor Mittal ex Belgo Mineira), mineração (VALE), logística (VLi Multimodal), celulose (Portocel) e Engenharia (PRDW Consulting). Especializado no desenvolvimento e coordenação de projetos complexos de operações logísticas no Brasil e exterior integrando os modais rodoviário, ferroviário e aquaviário à portos e terminais, provendo importantes resultados financeiros. Forte atuação com planejamento de longo prazo sendo responsável pela elaboração dos Planos Diretores Operacionais de logística das principais ferrovias e terminais portuários de granéis sólidos do Brasil, através da análise e tratamento de dados, modelagem de processos, especificação e validação de modelos de simulação, identificação de gargalos, mensuração capacidade e elaboração de projetos. Na área de tecnologia destaco o projeto de customização do sistema ERP e BI SAP para Rede Nacional de Distribuição de Aço e Estudo de Viabilidade do Porto 4.0 e maior terminal de contêiner do Peru (1,5 MTEUs). Destaco também experiência na área de gestão pela Qualidade, revalidando certificações ISO 9001, 14001 e OHSAS 18.000, aquisição de certificações ABNT e implantação de sistemas integrados de gestão, GPD, TNC e documentos.",
    photo: "img/sc1.png",
  },
  {
    name: "Thales Alves Rebouças Junior",
    formacao: "Analista de Sistema",
    faculdade: "Universidade Federal do Rio de Janeiro (UFRJ)",
    expirience:
      "30 anos de atuação em processos e projetos multidisciplinares, no Brasil e exterior, nas áreas de logística, mineração, portos, ferrovia, pelotização, indústria de alimentos, agropecuária e finanças. Em sua carreira teve a oportunidade de exercer funções de liderança em auditorias, coordenação de projetos (expansão operacional e tecnologia), comitês de normatização e engenharia, supervisor de operações (embarque, exportação e navegação) e supervisor de PPC. Importante citar a experiência internacional no negócio de mineração, exercendo função no escritório comercial nos EUA e projetos de expansão em Omã, Moçambique e Malásia, além do Projeto de TI “One VALE Americas” para implantação do sistema SAP. Destacamos também as expertises e habilidades em negociações, prospecção e desenvolvimento de negócios, o desenvolvimento de KPIs, o gerenciamento de projetos, a análise de viabilidade econômica de projetos, a liderança de equipes operacionais, a negociação técnica e comercial de contratos e implantação de ISO 14.000.",
    photo: "img/sc2.png",
  },
];
