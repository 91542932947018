import styled from 'styled-components';

export const Box = styled.footer`
    width: 100%;
    padding: 20px;
    background: #7F7F7F;
    color: #f0f0f0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    .title {
        font-size: 1.1rem;
        font-weight: 400;
    }
    .footerLink,
    .adress {
        font-weight: 500;
        color: #f0f0f0;
        font-size: 14px;
        &:hover {
            text-decoration: underline;
        }
        .icon {
            color: #25D366;
        }
    }
`;