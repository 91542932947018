import React from "react";

import * as C from "./styles";
import { CardReadMore } from "../../../components";
import { dataProducts } from "../../../mock/eng";

const Products: React.FC = () => {
  return (
    <C.Container id="produtos">
      <header className="cabecalho">
        <h2>Products</h2>
      </header>
      <div className="productBx">
        {dataProducts.map((produto, index) => (
          <CardReadMore
            dataAttr={produto.dataAttr}
            hiddenContent={produto.hiddenContent}
            img={produto.img}
            txt={produto.txt}
            key={index}
          />
        ))}
      </div>
    </C.Container>
  );
};

export default Products;
