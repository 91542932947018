import React, { useState } from "react";
import { useLocation } from "react-router-dom";

import { IProduct } from "../../types";
import { CardReadMoreContainer } from "./styles";

const CardReadMore: React.FC<IProduct> = ({
  dataAttr,
  hiddenContent,
  img,
  txt,
}) => {
  const { pathname } = useLocation();

  const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

  const isBr = pathname === "/";
  const HiddenContent = hiddenContent;

  return (
    <CardReadMoreContainer isShowingMore={isShowingMore}>
      <figure className="imgBx">
        <img src={img} alt={`Produto`} />
      </figure>
      <div className="text">
        <h4>{dataAttr}</h4>
        {isShowingMore ? (
          <HiddenContent />
        ) : (
          <p>
            {txt.split(",").map((t, i) => (
              <div
                key={i}
                style={{
                  fontSize:
                    dataAttr === "Engenharia" || dataAttr === "Engineering"
                      ? "10px"
                      : "inherit",
                }}
              >
                {t}
              </div>
            ))}
            <br />
            {dataAttr === "Gestão" && !isShowingMore && (
              <span className="highlight about">
                {isBr ? "(Em Breve)" : "Soon"}
              </span>
            )}
          </p>
        )}
        <button
          type="button"
          onClick={() => setIsShowingMore((prevState) => !prevState)}
        >
          {!isShowingMore
            ? isBr
              ? "Leia Mais"
              : "See more"
            : isBr
            ? "Leia Menos"
            : "See Less"}
        </button>
      </div>
    </CardReadMoreContainer>
  );
};

export default CardReadMore;
