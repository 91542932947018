import React, { useState, useCallback } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { useLocation } from "react-router-dom";

import { LinkScroll, LogoScroll, NavBx, Flag } from "./styles";

const Navbar: React.FC = () => {
  const { pathname } = useLocation();

  const isPtbr = pathname === "/";

  const [isMobalMenuActive, setIsMobalMenuActive] = useState(false);
  const [scrollActive, setScrollActive] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.scrollY > 0) return setScrollActive(true);
    return setScrollActive(false);
  }, []);

  window.addEventListener("scroll", handleScroll);

  const handleClick = () => setIsMobalMenuActive((prevState) => !prevState);

  const closeMenu = () => setIsMobalMenuActive(false);

  return (
    <NavBx scrollProp={scrollActive}>
      <LogoScroll to="banner" onClick={closeMenu}>
        <img src="img/logo.png" alt="Logo" />
      </LogoScroll>
      <ul className={isMobalMenuActive ? "lista active" : "lista"}>
        <li>
          <LinkScroll to="sobre" onClick={closeMenu}>
            {isPtbr ? "Quem Somos" : "About Us"}
          </LinkScroll>
        </li>
        <li>
          <LinkScroll to="metodologia" onClick={closeMenu}>
            {isPtbr ? "Metodologia" : "Method"}
          </LinkScroll>
        </li>
        <li>
          <LinkScroll to="produtos" onClick={closeMenu}>
            {isPtbr ? "Produtos" : "Products"}
          </LinkScroll>
        </li>
        <li>
          <LinkScroll to="projetos" onClick={closeMenu}>
            {isPtbr ? "Projetos" : "Projects"}
          </LinkScroll>
        </li>
        <li>
          <LinkScroll to="socios" onClick={closeMenu}>
            {isPtbr ? "Sócios" : "Partners"}
          </LinkScroll>
        </li>
        <li className="flagBox">
          <Flag to="/">
            <img src="img/brFlag.jpg" alt="Bandeira" />
          </Flag>
          <Flag to="/eng">
            <img src="img/engFlag.jpg" alt="Bandeira" />
          </Flag>
        </li>
      </ul>
      <button className="toggle" onClick={handleClick}>
        {isMobalMenuActive ? <FaTimes /> : <FaBars />}
      </button>
    </NavBx>
  );
};

export default Navbar;
