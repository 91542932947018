import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    background: transparent;
    outline: 0;
    border: none;
    list-style: none !important;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
  }

  body {
    max-width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }

  .app {
    position: relative;
    width: 100%;
    min-height: 100vh;
  }

  img {
    display: block;
    user-select: none;
  }

  a {
    display: inline-flex;
    align-items: center;
  }

  button {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
  }
  .highlight {
    font-weight: 600;
    color: #e50914;
    display: inline-block;
    &.about {
      color: #FF9933 !important;
    }
  }
`;

export default GlobalStyle;
