import React from "react";

import * as C from "./styles";
import { partnerData } from "../../../mock/ptbr";

const Partners: React.FC = () => {
  return (
    <C.Wrapper id="socios">
      <h2>Sócios</h2>
      <div className="scBx">
        {partnerData.map((socio, index) => (
          <C.Card key={index}>
            <div className="header">
              <figure className="imgBx">
                <img src={socio.photo} alt={`Sócio ${index + 1}`} />
              </figure>
              <span className="name">
                {socio.name}
                <br />
                <p style={{ fontSize: "1.2rem", opacity: ".5" }}>
                  {socio.formacao}
                </p>
              </span>
            </div>
            <div className="content">
              <p className="facul">{socio.faculdade}</p>
              <p>
                <b>Experiência:</b> {socio.expirience}
              </p>
            </div>
          </C.Card>
        ))}
      </div>
    </C.Wrapper>
  );
};

export default Partners;
