import React from "react";

import { IProduct, IProject, IPartner } from "../types";

export const dataProducts: IProduct[] = [
  {
    dataAttr: "Engineering",
    img: "img/prod2.png",
    txt: "Conceptual Study Feasibility Study Engineering Projects Operation Master Plan Dynamic Simulation",
    hiddenContent: () => (
      <>
        <p>
          <span className="highlight">Project</span> aims to solve a specific
          problem in the shortest possible time and may require several
          specialties. A logistics operations project has as a content the
          project criteria (assumptions, specifications and requirements such as
          service level), process design, operations optimization, resource
          dimensioning, system capacity analysis, risk analysis and economic
          feasibility. The layout of a facility or infrastructure and the result
          of operations <span className="highlight">simulation</span> are also
          important products, the latter mainly for complex, discrete and
          stochastic operations. During the development cycle, projects gain
          maturity after each detailing stage. The first stage, Conceptual Study
          is characterized by the analysis of several alternatives. Next, the
          Feasibility Study details the best alternative identified in the
          previous phase. Structural Engineering Projects may be required in
          this stage (4Decision works with partner consultants),{" "}
          <span className="highlight">however, those are main peace</span> in
          the third stage called Basic Project.
        </p>
        <p>
          A <span className="highlight">Master Plan</span> looks forward to
          reduce or block the impacts came from outside company usually related
          to the market or economic, technological, social, political, legal and
          environmental aspects. It proposes different solutions and/or a set of
          projects, usually detailed in the next 5 years, but with an analysis
          scope of 20 to 30 years. The Master Plan is{" "}
          <span className="highlight">annually</span> reviewed and serves as a
          guideline for the company's long-term planning.
        </p>
        <img src="img/prod1-inner-image.jpg" alt="Pau no cu" />
        <p>
          <span className="highlight">Dynamic simulation</span> of processes or
          systems is a very useful operational research technique for making
          decisions about a complex logistical operations in your company. It
          allows you to model, in other words, to represent any operation in the
          computer, for instance the flow of people or vehicles in a region of
          your city, with a high accuracy, no matter how stochastic or
          non-standard its activities are.
        </p>
        <p>
          That is possible due to our team experience and resources of
          programming technology and animation, besides statistical analysis of
          data, offered by software and digital platforms in the market. Among
          results, it measures operational capacity, identifies bottlenecks,
          indicates resources and service level, accuratelly considering the
          simultaneity of events, in a systemic and holistic way. And most
          importantly, it simulates future scenarios, making possible to test
          different solutions on clipboard, ensuring confidence in your
          decision-making process, enabling to you the best solution with less
          investment.
        </p>
      </>
    ),
  },
  {
    dataAttr: "Commercial",
    img: "img/prod1.png",
    txt: "Business Plan.",
    hiddenContent: () => (
      <p>
        {" "}
        <span className="highlight">Business Plan</span> is the result of a
        process development of the business strategic planning, which can be
        applied to a new product or new market reposition of the company.
        Depending on the application, different techniques and methods can be
        used, such as Porter's 5 Forces, SWOT, Mission, Vision and Values,
        strategic objectives, BSC, SIPOC, Economic Feasibility, among others.
        The product is usually an executive presentation and a detailed report.
      </p>
    ),
  },
  {
    dataAttr: "Management",
    img: "img/prod3.png",
    txt: "Data Base, Software and Hardware Evaluation for Business Management - a Startup for Digital Transformation",
    hiddenContent: () => (
      <p>
        Management focused on integrated information management, aims to analyze
        the sufficiency (quantification) and quality of your company's
        information. It demands several specialties (i) business management,
        evaluating the model of management and control of your company through
        strategic business indicators, key performance indicators (KPIs) and
        others operational benchmark parameters, (ii) Business Intelligence (BI)
        to evaluate your infrastructure of software and hardware, (iii)
        Automation, to evaluate the automation level of your company's
        operations, and (iv) Artificial Intelligence, to analyze the application
        of predictive and projection models usually used by the marketing,
        commercial and strategic planning areas.
      </p>
    ),
  },
  {
    dataAttr: "Educational",
    img: "img/prod4.png",
    txt: "Training for Management and Technical Tools",
    hiddenContent: () => (
      <p>
        Training of new tools and methodologies usually applied during
        4Decision's consultancy, ensuring the spreading of knowledge in your
        company and the maximization of your results.
      </p>
    ),
  },
];

export const dataProjects: IProject[] = [
  {
    title:
      "Conceptual Project of a 4.0 Container Port Terminal: Phase 1 of Largest Port Terminal in Peru 1.5 MTEUs (2019)",
    img: "img/img8.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Feasibility Study of a Public Port Leasing for Wheat Grinding Mill (2018)",
    img: "img/img2.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Trade Off Study of Alternatives for grain ship loading using closed hold (2017))",
    img: "img/img3.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Feasibility Study of a Coal Port Terminal for Dusty Suppression (2016)",
    img: "img/img4.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Operational Master Plan for major Iron Ore (120 Mtpy) and Coal and Grain (40 Mtpy) Port Terminals of Brazil (2012-15)",
    img: "img/img5.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Modeling, Accuracy Analysis and Validation of a dynamic simulator for a fertilizer and grain port terminal operation (8 Mtpy)",
    img: "img/img6.png",
    icon: "img/proj-icon.png",
  },
  {
    title:
      "Planning, contracting and execution of a new 3PL export channel for 100 kt of iron ore (2008)",
    img: "img/img7.png",
    icon: "img/proj-icon.png",
  },
];

export const partnerData: IPartner[] = [
  {
    name: "Luiz André Gervásio de Menezes",
    formacao: "Civil Engineer ",
    faculdade: "Federal University of Espírito Santo (UFES)",
    expirience:
      "Civil Engineer graduated from the Federal University of Espírito Santo (UFES) with extension courses in Integrated Logistics and Project Management, with 21 years of experience playing in the logistics business for the world's largest players in the steel industry (Arcelor Mittal), mining (VALE), logistics (VLi Multimodal), cellulose pulp (Portocel) and Engineering (PRDW Consulting). Specialized in development and coordination of complex projects for logistical operations in Brazil and abroad, integrating road, rail and waterways to ports and dry terminals, providing important financial results. Strong performance with long-term planning, being responsible for the Operational Master Plans for main national railways and dry bulk port terminals, through data analysis, process modeling, specification and validation of simulation models, identification of bottlenecks, measuring capacity and designing projects. For technology area, I highlight the customization of a new National Steel Distribution Network in SAP´s ERP and BI system and the Feasibility Study for Port 4.0 largest container terminal in Peru (1.5 MTEUs). I also highlight experience in the Quality Management area, ISO 9001, 14001 and OHSAS 18,000 systems certifications, ABNT´s product certifications and implementation of integrated management systems, GPD, TNC and documents.",
    photo: "img/sc1.png",
  },
  {
    name: "Thales Alves Rebouças Junior",
    formacao: "System Analyst",
    faculdade: "Federal University of Rio de Janeiro (UFRJ)",
    expirience:
      "Over 30 years of experience in multidisciplinary areas, in Brazil and abroad, for different business such as logistics, mining, ports, railroad, pelletizing, food industry, agriculture and finance. During career, he had the opportunity to exercise leadership roles in audits, project coordination (operational expansion and technology), standardization and engineering committees, operation management (shipping, exportation and navigation) and PPC supervisor. International experience in mining business, playing role at commercial office in US and expansion projects in Oman, Mozambique and Malaysia, in addition to IT Project 'One VALE Americas' for SAP´s ERP system implementation. We also highlight the expertise and skills in compliance, business development, KPIs management, project management, project economic feasibility analysis, operational team leadership, contracts negotiation and ISO 14.000 implementation.",
    photo: "img/sc2.png",
  },
];
