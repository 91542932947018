import React from "react";
import { IoEyeSharp, IoDiamond, IoCheckbox } from "react-icons/io5";

import * as C from "./styles";

const About: React.FC = () => {
  return (
    <>
      <C.Container id="sobre">
        <div className="textBx">
          <div className="text">
            <h2>Who we are</h2>
            <p>
              4Decision started up in 2021 to offer a specialized technical
              assistance service, based throughout the professional career of
              its partners, engineer Luiz André Gervásio de Menezes and systems
              analyst Thales Alves Rebouças Junior, with over 22 and 30 years of
              experience respectively, playing for biggest industries of steel,
              mining, logistics, pelletizing, cellulose pulp and others business
              of the world. The logistics market is our focus with main
              expertise for:
            </p>

            <ul className="lista">
              <li>Port terminal operations</li>
              <li>Railway operations</li>
              <li>Industrial operations</li>
              <li>Supply Chain operations.</li>
            </ul>

            <p>
              4Decision and our team of partner consultants are specialized in
              port terminal operations for dry bulk (minerals and grains) and
              containers, where we performed our major professional experience!
              The passage through sectors of planning, projects, PPC, new
              business, engineering and logistics enables to offer a
              personalized service, whether an operational, tactical or
              strategic focus. We offer special attention to small and
              medium-sized customers to achieve their most challengeable and
              expansive goals, aggregating whenever possible large company best
              practices.
            </p>

            <p>
              Motivated by logistics, we are driven by its challenges like the
              systemic scope, the unpredictability, the absence of standards and
              the complexity interaction between events. These challenges demand
              4Decision to apply at all services a methodology comprising phases
              called problem diagnosis, problem key cause analysis and best
              solutions identification. It is essential to have a close synergy
              with the customer´s team, validating all steps, seeking greater
              effectiveness and efficiency.
            </p>
            <p>
              We highlight 4Decision differentials as a business consultancy
              pushed by an objective service and really useful product for our
              customers. It is translated to our MISSION to build a long-term
              partnership to our customers.
            </p>
          </div>
        </div>
        <figure className="imgBx">
          <img src="img/about-img.png" alt="Img-Sobre" />
        </figure>
      </C.Container>
      <C.IconBox>
        <C.IconCard>
          <C.CardHeader>
            <C.IconWrapper>
              <IoDiamond fontSize={36} />
            </C.IconWrapper>
            <span className="title">Values</span>
          </C.CardHeader>
          <C.CardBody>
            <p>Ethic</p>
            <p>Add Valued</p>
            <p>Efficiency</p>
            <p>Respect</p>
            <p>Partnership</p>
          </C.CardBody>
        </C.IconCard>
        <C.IconCard>
          <C.CardHeader>
            <C.IconWrapper>
              <IoCheckbox fontSize={36} />
            </C.IconWrapper>
            <span className="title">Mission</span>
          </C.CardHeader>
          <C.CardBody>
            <p>
              Provide tailor made solutions and build a long-term partnership,
              adding value and success to the client´s business.
            </p>
          </C.CardBody>
        </C.IconCard>
        <C.IconCard>
          <C.CardHeader>
            <C.IconWrapper>
              <IoEyeSharp fontSize={36} />
            </C.IconWrapper>
            <span className="title">Vision</span>
          </C.CardHeader>
          <C.CardBody>
            <p>
              Be recognized in its specialties, distinguished by technical,
              useful and efficient solutions.
            </p>
          </C.CardBody>
        </C.IconCard>
      </C.IconBox>
    </>
  );
};

export default About;
