import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    min-height: calc(100vh - 100px);
    padding: 40px 0;
    background: #151521;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    .cabecalho {
        width: 100%;
        padding: 5px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            position: relative;
            font-size: 3rem;
            font-weight: 500;
            color: #f5f5f5;
            cursor: pointer;
            transition: .3s;
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 40%;
                height: 2px;
                background: #f5f5f5;
                transition: .3s;
            }
            &:hover {
                letter-spacing: 5px;
            }
            &:hover::after {
                width: 100%;
            }
        }
    }

    .metoBx {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 809px;
        gap: 20px;
        .imgBx {
            max-width: 100%;
            object-fit: contain;
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        p {
            color: #f5f5f5;
            text-align: justify;
            font-size: 1.1rem;
            font-weight: 500;
        }
        & > * {
            max-width: 100%;
        }
        @media screen and (max-width: 1100px) {
            & {
                width: 100%;
                padding: 0 20px;
                .imgBx {
                    width: 100%;
                    img {
                        max-width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
`;