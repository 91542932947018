import styled from "styled-components";

export const Container = styled.section`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 100px);
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
  padding: 30px 40px;

  .imgBx {
    flex: 1;
    max-width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    img {
      display: block;
      max-width: 100%;
    }
    @media screen and (max-width: 700px) {
      & {
        width: 100%;
      }
    }
  }

  .textBx {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    z-index: 100;

    .text {
      position: relative;
      h2 {
        width: fit-content;
        font-size: 3rem;
        font-weight: 500;
        position: relative;
        cursor: pointer;
        margin-bottom: 1rem;
        transition: 0.3s;
        &::after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 40%;
          height: 2px;
          background: #0d0d0d;
          transition: 0.3s;
        }
        &:hover {
          letter-spacing: 5px;
        }
        &:hover::after {
          width: 100%;
        }
      }
      p {
        text-align: justify;
        line-height: 1.85em;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .lista {
        margin-left: 30px;
        li {
          list-style: disc;
        }
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 700px) {
      & {
        .text {
          h2 {
            font-size: 2rem;
          }
          p {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    & {
      min-height: 100vh;
      grid-template-columns: 1fr;
      padding: 10px 30px;
      grid-gap: 40px;
      .text {
        h2 {
          text-align: center;
        }
      }
    }
  }
`;

export const IconBox = styled.div`
  width: 100%;
  max-width: 100vw;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 3rem;
  padding: 0 4rem 1rem 4rem;

  @media screen and (max-width: 600px) {
    gap: 1rem;
    padding: 0 2rem 8px 2rem;
    justify-content: center;
  }
`;

export const IconCard = styled.div`
  width: 100%;
  max-width: 230px;
  min-height: 290px;
  background-color: #f0f0f0;
  color: #0d0d0d;
  padding: 1rem;
  border-bottom: 2px solid #0d0d0d;
`;

export const IconWrapper = styled.div`
  color: inherit;
  font-size: 2rem;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;

  .title {
    font-size: 18px;
    font-weight: 500;
  }
`;

export const CardBody = styled.div`
  width: 100%;
  padding: 10px;
  p {
    font-size: 16px;
    margin-bottom: 8px;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
