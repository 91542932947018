import React from "react";

import * as C from "./styles";
import { dataProjects } from "../../../mock/ptbr";

const Projects: React.FC = () => {
  return (
    <C.Container id="projetos">
      <h2>Projetos</h2>
      <div className="cardBx">
        {dataProjects.map((card, index) => (
          <C.Card key={index}>
            <figure className="imgBx">
              <img src={card.icon} alt="icon-proj" className="front-side" />
              <img src={card.img} alt={`Projeto - ${index + 1}`} />
            </figure>
            <div className="detalhes">
              <div className="content">
                <h3>{card.title}</h3>
              </div>
            </div>
          </C.Card>
        ))}
      </div>
    </C.Container>
  );
};

export default Projects;
