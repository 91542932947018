import React from "react";
import { IoEyeSharp, IoDiamond, IoCheckbox } from "react-icons/io5";

import * as C from "./styles";

const About: React.FC = () => {
  return (
    <>
      <C.Container id="sobre">
        <div className="textBx">
          <div className="text">
            <h2>Quem Somos</h2>
            <p>
              A 4Decision foi criada em 2021 para oferecer um serviço de
              assessoria técnica especializada, fundamentada ao longo da
              carreira profissional de seus sócios o engenheiro Luiz André
              Gervásio de Menezes e o analista de sistemas Thales Alves Rebouças
              Junior, com mais de 22 e 30 anos de experiência respectivamente,
              atuando para os maiores players do mercado mundial nos segmentos
              de siderurgia, mineração, logística, pelotização, celulose, entre
              outros. O mercado de logísticas é o nosso foco, com principais
              expertises:
            </p>

            <ul className="lista">
              <li>Operações Portuárias;</li>
              <li>Operações Ferroviárias;</li>
              <li>Operações Industriais;</li>
              <li>
                Rede de Distribuição e Cadeia de Suprimento (supply chain).
              </li>
            </ul>

            <p>
              A 4Decision e nosso time de consultores parceiros são
              especializados em operações de terminais portuários de granéis
              sólidos (minerais e grãos) e contêineres, onde tivemos maior
              atuação profissional! A passagem por setores de planejamento,
              projetos, PPC, novos negócios, engenharia e logística nos habilita
              a oferecer um serviço personalizado, seja com enfoque operacional,
              tático ou estratégico. Ofereceremos especial atenção aos clientes
              de pequeno e médio portes, incorporando sempre que possível
              técnicas e soluções “first class” praticadas nas grandes empresas,
              visando alcançar seus objetivos mais expansivos e disruptivos.
            </p>

            <p>
              Nossa expertise no desenvolvimento de projetos e estudos de
              viabilidade (EVTEA) atraiu o interesse de clientes com atuação na
              área Ambiental, Social e Governança (sigla em inglês ESG). A visão
              holística de nossos especialistas, possibilitou agregar e integrar
              os valores sociais, ambientais, de saúde e segurança do projeto,
              aos pilares operacionais e econômicos. Concluindo com uma
              avaliação de impacto às metas do Plano Nacional de Resíduos
              Sólidos - PNRS instituída pela lei nº 12.305/2010 e revisada pela
              novo Marco Legal do Saneamento, conforme lei nº 14.026/2020.
              Sempre perseguindo nossa Missão e Valores, de solucionar e agregar
              valores, realizamos também a análise de impacto do projeto nas
              Emissões de Gases Efeito Estufa (GEE), assim como estamos
              projetando a implantação da tecnologia de automação robótica de
              processos (sigla em inglês RPA) visando maior eficiência e
              agilidade e melhor governança na gestão de informação para nossos
              clientes.
            </p>
            <p>
              Como grandes entusiastas da logística, somos impulsionados pelos
              seus desafios, sua abrangência sistêmica, a imprevisibilidade, a
              ausência de padrões e a complexidade diária de variáveis
              características das operações. Estes desafios exigem sempre da
              4Decision a aplicação de uma metodologia de trabalho desenvolvida
              em fases, o diagnóstico do problema, a análise de causas e
              identificação de soluções. É também imprescindível a sinergia de
              perto com o cliente, validando todas estas etapas, buscando a
              maior eficácia e eficiência.
            </p>
            <p>
              O diferencial da 4Decision é oferecer um produto além de eficaz,
              objetivo e de utilidade prática para nosso cliente,{" "}
              <div className="highlight about">essência essa incorporada</div> à
              MISSÃO de nossa empresa. Queremos ser lembrados nos momentos de
              maiores desafios, para desenvolver as soluções certas e estar
              junto na hora de sua conquista, numa parceria de longo prazo!{" "}
            </p>
          </div>
        </div>
        <figure className="imgBx">
          <img src="img/about-img.png" alt="Img-Sobre" />
        </figure>
      </C.Container>
      <C.IconBox>
        <C.IconCard>
          <C.CardHeader>
            <C.IconWrapper>
              <IoDiamond fontSize={36} />
            </C.IconWrapper>
            <span className="title">Valores</span>
          </C.CardHeader>
          <C.CardBody>
            <p>Ética</p>
            <p>Agregar Valor</p>
            <p>Eficiência</p>
            <p>Respeito</p>
            <p>Parceria</p>
          </C.CardBody>
        </C.IconCard>
        <C.IconCard>
          <C.CardHeader>
            <C.IconWrapper>
              <IoCheckbox fontSize={36} />
            </C.IconWrapper>
            <span className="title">Missão</span>
          </C.CardHeader>
          <C.CardBody>
            <p>
              Fornecer soluções sob medida e criar uma parceria de longo prazo,
              agregando valor e sucesso ao negócio do cliente.
            </p>
          </C.CardBody>
        </C.IconCard>
        <C.IconCard>
          <C.CardHeader>
            <C.IconWrapper>
              <IoEyeSharp fontSize={36} />
            </C.IconWrapper>
            <span className="title">Visão</span>
          </C.CardHeader>
          <C.CardBody>
            <p>
              Ser reconhecida em suas especialidades, diferenciada pelas
              soluções técnicas, úteis e eficientes.
            </p>
          </C.CardBody>
        </C.IconCard>
      </C.IconBox>
    </>
  );
};

export default About;
