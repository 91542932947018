import styled from "styled-components";

export const Container = styled.section`
    width: 100%;
    padding: 20px 0;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;

    .productBx {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;
        flex: 1;
        padding: 0 40px;
        gap: 30px;
        @media screen and (max-width: 500px) {
            & {
                padding: 0 20px;
            }
        }
    }

    .cabecalho {
        width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            font-size: 3rem;
            font-weight: 500;
            color: #0d0d0d;
            cursor: pointer;
            transition: .3s;
            position: relative;
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 40%;
                height: 2px;
                background: #0d0d0d;
                transition: .3s;
            }
            &:hover {
                letter-spacing: 5px;
            }
            &:hover::after {
                width: 100%;
            }
        }
    }
`;
