import React from "react";
import { useLocation } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";

import { Box } from "./styles";

const Footer: React.FC = () => {
  const { pathname } = useLocation();

  const isBr = pathname === "/";

  return (
    <Box>
      <h3 className="title">
        4Decision Engenharia e Tecnologia Ltda &copy;
        {isBr && "Todos os Direitos Reservados."}
      </h3>
      <span>CNPJ 30.997.206/0001-07</span>
      <a
        href="https://api.whatsapp.com/send?phone=5527999589626&text=Quero%20saber%20mais!%20(4decision)."
        target="_blank"
        rel="noreferrer"
        className="footerLink whats"
      >
        <IoLogoWhatsapp className="icon" />: +55 27 99958-9626
      </a>
      <a
        href="https://api.whatsapp.com/send?phone=5527999589626&text=Quero%20saber%20mais!%20(4decision)."
        target="_blank"
        rel="noreferrer"
        className="footerLink"
        style={{ marginBottom: '10px' }}
      >
        Email: comercial@4decision.com.br
      </a>
      <a
        className="adress"
        href="https://www.google.com/maps/place/Condom%C3%ADnio+do+Edif%C3%ADcio+Tiffany+Center+-+Av.+Nossa+Sra.+da+Penha+-+Praia+do+Canto,+Vit%C3%B3ria+-+ES,+29055-131/@-20.3058598,-40.296865,3a,75y,258.62h,89.64t/data=!3m7!1e1!3m5!1swLFGIjCBgOUPeZbRb0G_OQ!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DwLFGIjCBgOUPeZbRb0G_OQ%26cb_client%3Dsearch.gws-prod.gps%26w%3D86%26h%3D86%26yaw%3D260.6094%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m8!1m2!2m1!1sAvenida+Nossa+Senhora+da+Penha,+Ed.+Tiffany+Center,+Torre+B,+n%C2%BA+595,+sala+1305+Santa+L%C3%BAcia,+Vit%C3%B3ria+(ES),+Brasil+-+CEP+29.056-250!3m4!1s0xb817fb5877db3d:0x1dcb54a6d99d7b34!8m2!3d-20.3058797!4d-40.2969643"
        target="_blank"
        rel="noreferrer"
      >
        Avenida Nossa Senhora da Penha, Ed. Tiffany Center,{" "}
        {isBr ? "Torre" : "Tower"} B, nº 595, {isBr ? "sala" : "room"} 1305
        Santa Lúcia, Vitória (ES), {isBr ? "Brasil" : "Brazil"} - CEP 29.056-250
      </a>
    </Box>
  );
};

export default Footer;
